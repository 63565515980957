import React, { useEffect, useState } from 'react';
import { Margin } from 'styled-components-spacing';
import Alert from '@nib-components/alert';
import axios, { AxiosResponse } from 'axios';
import config from '../../utils/env';

const query = `
query{
  simpleWebsiteContentCollection(where: {
    urlSlug_contains:"public-announcement" 
  }) {
    items{
      urlSlug
      pageContent
    } 
  }
}
`;

interface ContentfulWebsiteContentCollectionResponse {
  data?: ContentfulWebsiteContentCollection;
}

interface ContentfulWebsiteContentCollection {
  simpleWebsiteContentCollection?: SimpleWebsiteContentCollectionArray;
}

interface SimpleWebsiteContentCollectionArray {
  items?: ContentfulSimpleWebsiteContent[];
}

interface ContentfulSimpleWebsiteContent {
  pageContent: string;
}

const getWebsiteAnnouncement = (): Promise<
  AxiosResponse<ContentfulWebsiteContentCollectionResponse>
> => {
  let contentURL = `https://graphql.contentful.com/content/v1/spaces/ja9v5o5o08yv/environments/{ENVIRONMENT}?access_token={ACCESSTOKEN}`;
  contentURL = contentURL.replace('{ACCESSTOKEN}', config.contentfulAccessToken);
  contentURL = contentURL.replace('{ENVIRONMENT}', config.contentfulEnvironment);
  return axios.post(contentURL, { query: query });
};

const NotificationBanner = (): JSX.Element | null => {
  const [simpleContent, setSimpleContent] =
    useState<ContentfulWebsiteContentCollectionResponse | null>(null);
  const fetchWebsiteAnnouncement = async (): Promise<void> => {
    try {
      const result = await getWebsiteAnnouncement();
      setSimpleContent(result.data);
    } catch (e) {
      setSimpleContent(null);
    }
  };
  useEffect(() => {
    fetchWebsiteAnnouncement();
  }, []);

  const items = simpleContent?.data?.simpleWebsiteContentCollection?.items || [];
  const hasContent = items.length > 0 && items[0].pageContent !== null;
  return (
    <>
      {hasContent ? (
        <Margin bottom={4}>
          <Alert type="info" fullWidth={true}>
            <div
              dangerouslySetInnerHTML={{
                __html: items[0].pageContent.replace(/^<p>|<\/p>$/g, ''),
              }}
            />
          </Alert>
        </Margin>
      ) : null}
    </>
  );
};

export default NotificationBanner;
