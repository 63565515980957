import moment from 'moment';

export const compareDateString = (aDate: string, bDate: string): number => {
  const aMomentDate = moment(aDate);
  const bMomentDate = moment(bDate);

  if (aMomentDate.isBefore(bMomentDate)) {
    return 1;
  } else if (aMomentDate.isAfter(bMomentDate)) {
    return -1;
  } else {
    return 0;
  }
};

export const getFormattedDateFromDateString = (dateString: string): string => {
  return moment(dateString).format('DD MMM YYYY');
};
